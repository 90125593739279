<template>
    <div class="flex flex-col rounded border-2 border-black p-2 mb-1 lg:mb-6 shadow-lg">
        <span class="text-xl">Our Rates</span>
        <span class="pl-6">[25$/hour - 50$/hour]</span>
    </div>
</template>
<script>
export default {
    name: 'Cost',
}
</script>