<template>
  <div class="flex flex-col rounded border-2 border-black p-2 shadow-lg">
    <span class="text-xl">Experience</span>
    <span class="pl-6 mb-1"
      >My name is Patrique Legault, I have a Bachelors degree in Mathematics and
      Computer Science as well as a Master's degree in eBusiness Technology. I
      have been working in the world of high tech for over 10 years. Now I am
      starting a new venture in the field of education. I am looking to provide help to
      students and adults who are looking to gain knowledge and understanding in either
      topics of Mathematics or Computer Science.
    </span>
    <div class="pl-6 mb-1">
      <span
        >Some of the topics that I have much expienrence are listed below:</span
      >
      <ul class="underline pl-10 list-disc">
        <li>JavaScript</li>
        <li>Java</li>
        <li>HTML/CSS</li>
        <li>Calculus</li>
        <li>Functions</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Experience",
};
</script>